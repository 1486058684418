<template>
  <div class="appoint">
    <div class="appointBox content">
      <div class="appointLeft">
        <img src="https://image.ceekee.com/pc/tripwise/20210112_1.png" />
      </div>
      <div class="appointright">
        <p style="padding-left: 100px">立即体验</p>
        <p style="padding-left: 20px; font-size: 16px">咨询请联系：180-5110-2396(同微信)</p>
        <div class="inputLi">
          <div class="liBorder">
            <i class="iconfont iconiphone"></i>
            <el-input v-model="phone" placeholder="预约手机号"></el-input>
          </div>
        </div>
        <div class="inputLi">
          <div class="liBorder code">
            <i class="iconfont iconyanzhengma1"></i>
            <el-input v-model="smsCode" placeholder="短信验证码"></el-input>
          </div>
          <AliyunCaptcha ref="AliyunCaptcha" @BizResult="getBizResult"></AliyunCaptcha>
          <p v-if="!count" id="sendSms" @click="sendSms">发送验证码</p>
          <p v-else>{{ count }}s</p>
        </div>
        <div class="inputLi">
          <div class="checkInfo checkBox">
            <el-checkbox v-model="checked"></el-checkbox>
          </div>
          <div class="checkInfo">
            同意
            <span @click="dialogVisible = true">《差旅管家TripWise服务使用协议》</span>
          </div>
        </div>
        <el-button type="primary" native-type="submit" @click="submits">提交</el-button>
      </div>
    </div>
    <el-dialog title="差旅管家TripWise服务使用协议" :visible.sync="dialogVisible" :lock-scroll="false" width="80%">
      <span>
        本协议是用户（以下简称“您”）与“差旅管家TripWise”平台（包括但不限于网址：http://www.tripwisesh.cn、https://www.ceekee.com、手机客户端、微信公众号等，简称“本平台”）所有者及其关联公司（以下简称为“思客”）之间就平台网络服务等相关事宜所订立的契约。
        在此特别提醒您：在使用本平台相关服务前，您应当注册成为本平台用户。您进行用户注册时，请仔细阅读并完全理解本协议的全部条款，本平台已将本协议限制、免除责任或限制用户权利的条款特别提请您的注意，如您不同意本服务条款的任意内容，请勿注册或使用本平台的用户服务。如您注册或登录成功，即表示您完全接受本协议所有条款的约束，此后，您不得以未阅读本服务条款内容作任何形式的抗辩。
        <br />1. 签约提示 <br />1.1
        您按照本平台规定的用户注册程序开始注册或登录，即表示您已充分阅读、理解并同意接受本协议的条款和条件，并签署了本协议，本协议即对您产生法律效力。
        <br />1.2
        您一经注册为本平台用户，即视为您同意本平台在将来任何时刻有权根据公司业务需求（包括但不限于产品升级、平台发展及运作需要、交易安全保护等）、互联网的发展或中华人民共和国有关法律、法规的变化，不时地修改本协议条款或平台各类规则,并在平台上予以公布。经修订后的协议、规则一经本平台公布，立即自动生效。用户的权利以及义务的表述，均以最新的服务条款为准。若您不同意相关变更,
        则您应立即停止使用本服务，否则不能豁免您在本平台已经进行的交易项下所应承担的义务。 <br />1.3
        本协议的效力范围及于本平台的一切产品和服务。当您使用本平台各单项或多项服务时，您的使用行为视为您同意该单项或多项服务的服务条款以及本平台在该单项或多项服务中发出的各类公告及/或规则。
        <br />2. 用户注册 <br />2.1
        本平台仅向符合中华人民共和国法律规定的具有完全民事权利能力和民事行为能力，能够独立承担民事责任的企业提供服务。若您不符合此等条件，请勿注册，否则本平台有权随时中止或终止您的用户资格。
        <br />2.2
        您按照本平台提示的注册操作流程同意本服务协议、填报注册信息、上传相关资料，并通过企业认证后即可成功注册为本平台的用户。若您提供任何不真实、不准确、不完整或不能反映当前实际情况的资料，或您冒用、盗用或以其他未经授权方式使用他人资料进行注册登记的，或本平台有合理理由怀疑该等资料不真实、不准确、不完整或不能反映当前情况，由此引起的一切后果概由您独立承担，本平台有权拒绝您的申请或者撤销您的用户资格。
        <br />2.3
        请您维持并及时更新用户资料，使其保持真实、准确、完整和反映当前情况，否则由此造成的用户权利不能全面有效地行使的责任由您自行承担。
        <br />3. 服务内容
        <br />您通过本平台的网络及技术支持可使用思客及/或第三方供应商提供的以下服务项目（后续根据本平台运作需要将增加服务项目，具体以平台公布的内容为准）：
        <br />(1) 机票、火车票、酒店订购服务； <br />(2) 企业用车服务； <br />(3) 企业用品采购服务； <br />(4)
        企业人力资源福利外包服务； <br />(5) 海外移民、医疗信息咨询服务； <br />(6) 员工健康管理、体检预约服务；
        <br />(7) 快递下单； <br />(8) 油卡购买； <br />(9) 本平台提供的其他服务。 <br />4. 费用及结算流程 <br />4.1
        费用
        <br />本平台上的各项服务的收费（包括但不限于购买收费、预订收费、改签收费、退改/退票收费等），以您在使用服务时本平台及/或第三方供应商网络平台上所公示之收费说明或另行签订的电子协议为准，且默认以人民币作为本平台上所有交易的结算币种。您完全接受并同意，本平台及/或第三方供应商有权不时调整前述各项消费服务费用的类型或金额等具体事项，并根据本协议或相关规则进行公告、修改。在您按照平台操作指示购买、预订相关服务时，您即已同意对此服务费用的类型或金额的调整。
        <br />4.2 结算方式 <br />4.2.1 您可通过以下方式就其购买的产品/服务进行结算： <br />(1)
        预存款中结算。即您向第三方供应商指定的账户先预存一定金额的款项，用于后续向第三方购买相关服务/产品，购买行为发生时，则按照您的管理员账户或员工账户在设定的权限范围内发出的付款指令，第三方供应商即将预存款内相应金额的交易价款直接扣收。
        <br />(2)
        实时结算。即当您的管理员账户/员工账户内预存的款项不足以支付将发生的交易价款时，或您的员工账户无权限要求交易价款从预存的款项中扣付给第三方供应商时，您指定的授权负责人/员工可通过本平台提供的支付工具实时向第三方供应商支付交易价款。
        <br />(3)
        信用结算（或分期结算）。该结算方式需由您主动提起申请，经第三方供应商审核您的资信状况后批准一定金额的信用消费额度（以下称“信用额度”），您在该信用额度内可先进行消费。自您使用信用额度发生第一笔消费之日起，1个自然月为一个账单周期，账单周期届满时您可选择立即支付或分期支付上一账单周期的应付账款。根据您所选择的不同付款方式，第三方供应商将可能收取一定金额的手续费、利息等费用（以平台公布为准）。无论您采取前述哪种付款方式，只要在此前尚有未结算的账款，则您的信用额度将体现为剩余信用额度，即首次给予您的信用额度扣减未结算账款后的金额。
        <br />4.2.2
        您可自行在本平台的后台操作系统中下载（或通过客服申请发送）一定期限内已发生消费对应的账单。账单金额包含本协议项下对应账期所发生订单对应消费金额、服务费、历史累计已付款、历史累计应付未付款（如有）、历史累计违约金（如有）及其他在本协议项下您应向第三方供应商支付金额等。
        <br />4.3 发票/报销凭证 <br />4.3.1
        原则上，您在当月10日之后可自行在本平台的后台操作系统中申请开具上一自然月已发生消费对应的发票/合法报销凭证（如机票行程单、火车票），但个别服务项目因供应商原因可能在发票/合法报销凭证出具方面与上述原则不同，具体以平台的公布为准，您在购买服务时可通过平台展示知悉该等特殊规则，如您不同意该等特殊规则，请勿购买相关服务，否则将视为您同意执行该等规则。
        <br />4.3.2
        本平台特别向您告知并声明：上述第4.3.1条所述“应提供的发票/合法报销凭证”中，国外航空公司售卖的机票、您通过本平台预订酒店后用现金直接结付给酒店方的住宿费、及其他不能/无法提供发票的服务项目（以平台公布为准），思客及/或第三方供应商不提供相应的发票/合法报销凭证，由您自行向国外航空公司、酒店或相关方索要。
        <br />4.3.3
        根据航空公司要求，机票退票手续费不提供任何形式报销凭证。您如需思客提供机票退票手续费发票，需自行承担退票手续费的增值税款项（对应机票退票手续费金额的
        6%）。 <br />4.3.4
        若您无法归集火车票，需思客提供火车票发票，需自行承担火车票票款对应的增值税款项（对应火车票票款金额的6%）。
        <br />4.3.5 本平台每月向用户寄送发票的快递费，若您就特定服务事项需加急索取相关凭证的，快递费用由您承担。
        <br />5. 用户权利、义务 <br />5.1 用户账户 <br />5.1.1
        您成功注册为本平台的用户后，即建立了自己的平台用户账户（以下称“管理员账户”），在此管理员账户账户之下，您可以建立多个子账户（以下称“员工账户”），并设置员工账户的相关权限及规则。
        <br />5.1.2
        您须指定全权代表您处理企业消费事务的授权负责人，由该授权负责人妥善保管管理员账户的用户名和密码，确保该用户名及密码的机密安全，并确保该授权负责人使用管理员账户所进行的一切行为均代表您，并承担其行为产生的一切后果及责任。任何因管理员账户所发生的交易，均由您承担全部后果及责任。当授权负责人信息发生变化时，您应联系本平台客服进行信息变更。
        <br />5.1.3
        您确认，一旦建立员工账户，即表示您对于享有员工账户用户名和密码的人系基于合法授权，任何因该员工账户所发生的交易，除指定员工自行支付外，均由您承担全部后果及责任。
        <br />5.2 用户指示 <br />5.2.1
        您（您指定的授权负责人/员工）使用账户的用户名和密码登录本平台后，可按照相关服务流程和权限在本平台/第三方供应商网络平台上向本平台/第三方供应商发出服务/购买指示。
        <br />5.2.2
        本平台通过用户名和密码来识别用户的身份，您指定的授权负责人及/或其员工在使用用户名和密码登录本平台后的任何指示（无论是否故意），一经发出，在未经本平台书面同意的情况下，一概不得取消或撤回。所有此等已发出的指示，不论由用户自身或声称为用户的任何其他人士发出，经本平台按其诚信理解并执行后，即不可撤销，且对用户有约束力。除核证用户的用户名和密码外，本平台并无任何责任核对任何发出此类指示的人士身份或权限，或核对此类指示的真实性。
        <br />5.3 用户账户暂停、终止或限制访问 <br />5.3.1
        您决定不再使用本平台服务而需终止用户账户时，思客/第三方供应商基于充值优惠或其他优惠活动所赠送给您的消费券全部失效，用户账户内剩余的预付款项将退回。
        <br />5.3.2
        无论是否收费，只要您利用本平台从事违法活动或者严重违反本协议的约定，本平台可在不发出任何通知的情况下立即使您的账户无效，或撤销您的账户以及在账户内的所有相关资料和档案，和/或禁止您进一步接入该等档案或服务。帐号终止后，本平台没有义务为用户保留原帐号中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给您或第三方。此外，本平台亦不会就终止账户使用而向您或任何第三者承担任何责任。
        <br />5.3.3
        您账户的暂停、中断或终止不代表您责任的终止，您仍应对其使用本平台服务期间的行为承担可能的违约或损害赔偿责任。
        <br />6. 知识产权保护 <br />6.1
        本平台所有内容和服务，包括但不限于网站、APP的整体结构、页面设计、文字、图片、图表、软件、视频文件、音频文件、广告以及本平台为用户提供的其它信息或资料，其知识产权属思客或其他相关方所有。未经思客或其他相关方许可，任何人不得为了商业目的进行复制或者以其它方式进行非法使用。
        <br />6.2 任何未经授权许可而使用本平台内容的行为均属于违法行为, 思客保留追究相关使用人法律责任的权利。 <br />7.
        隐私权保护条款 <br />7.1
        您充分理解并完全接受：在使用本平台的服务及/或通过本平台进行消费时，思客有可能会收集您作为用户的如下信息（以下称“用户信息”）：
        <br />(1) 用户自身的信息； <br />(2) 用户提供给第三方或者向第三方披露的信息及隐私； <br />(3)
        用户登录和使用本平台的时间、时长、支付账号、购物记录、系统日志信息以及行为数据（包括但不限于订单下达及取消数据、退货退款申请数据、手机钱包账户余额、交易纠纷数据等）；
        <br />(4) 用户使用的台式计算机、移动设备的品牌、型号、IP地址以及软件版本信息； <br />(5)
        通过cookie或者其他方式自动采集到的用户的其他信息或者隐私。 <br />7.2
        本平台尊重用户的隐私权，不会公开、编辑或泄露任何用户信息，但以下情况除外： <br />(1) 事先获得用户的明确授权；
        <br />(2) 遵守法律规定或应国家司法/政府机关基于法定程序要求； <br />(3) 维护社会公众利益； <br />(4)
        维护本平台的合法权益； <br />(5) 符合其他合法要求。 <br />7.3
        您充分理解并完全接受：即便是本平台采取各种安全技术和程序存储、保护用户信息，防止其被未经授权的访问、使用、复制和泄露，但用户信息仍然有可能发生被黑客攻击、窃取，因不可抗力或者其他非本平台自身原因而被泄露的情形。对此，只要是本平台采取了必要的措施防止上述情形之发生，并在上述情形发生之后采取必要的措施防止其损失进一步扩大，则本平台无须赔偿由此给您造成的任何损失。
        <br />7.4
        您同意，本平台有权对您提供的信息进行整理、分析，可将有服务需求人员的相关个人信息提供给实际终端服务方及中间所涉及的第三方供应商（如酒店、机场、铁路、预订中介、
        预订平台、票务代理等）。同时您同意，本平台有权为提高服务质量、改善自身管理效率或发生并购、重组及其他组织形式变更时，将因本协议获取的您及其他您人员信息及前述保密信息提供给与前述事项相关并已签署保密协议的关联方及合作的第三方主体，并有权对该等数据和信息进行收集、整理、分析、合理使用。
        <br />8. 免责声明 <br />8.1
        任何您与第三方供应商发生交易而产生的风险，由交易各方自行承担，第三方供应商对其服务/产品品质独立负责，您对自己的判断独立负责。您理解并确认，本平台对提供服务过程中发生因第三方原因导致的损失，包括但不限于因航空公司、酒店、铁路部门、供应商平台等原因导致的系统错误、身份验证
        失败、价格变更、服务变更等，以及意外事故（包括但不限于服务使用者的意外医疗、伤残、意外死亡）产生的纠纷免责，但本平台应尽力协助您与相应服务的供应商解决有关问题。
        <br />8.2
        因不可抗力或服务器死机、网络故障、数据库故障、软件升级等问题造成的服务中断和对您个人数据及资料造成的损失，本平台不承担任何责任，亦不予赔偿，但将尽力减少因此而给您造成的损失和影响。
        <br />8.3 因黑客、病毒或密码被盗、泄露等非本平台原因所造成损失概由您自行承担。 <br />9. 违约赔偿 <br />9.1
        任一方因违反本协议或本协议项下的其他文件，或违反了任何法律、法规的规定，给相对方造成损失的，应予赔偿，赔偿范围包括但不限于直接损失、间接损失以及维权费用等。
        <br />9.2
        任一方因违反前款规定而侵害了第三方的权利，导致相对方遭受任何第三方提起的索赔、诉讼或行政责任，违约方承诺无条件承担相应责任并使相对方免责。若因此给相对方造成损失的，应予赔偿，赔偿范围包括但不限于维权费用、名誉损失以及向第三方支付的补偿金等。
        <br />10. 通知 <br />10.1
        除非另有书面约定，本平台向您发出的任何通知均以电子邮件或者手机短信形式发送到通知发出之时的用户资料所记载的电子邮件地址或/和手机号码，电子邮件或者短信一经发出即视为送达并产生相应法律效力。您应密切关注邮箱及手机信息，并同意：本平台按照通知发出之时用户资料所记载的电子邮件地址或/和手机号码发出通知，本平台不对您未能收到电子邮件或短信通知承担任何责任。
        <br />10.2
        您若因注册登记的电子邮件地址或手机号码发生变化而未及时进行资料更新，以致于本平台通知无法送达或者用户收不到通知，亦或因您未及时关注邮箱及手机信息而导致相关通知内容失效的，本平台不承担任何责任，所有后果应由您自行承担。
        <br />11. 法律适用与争议解决 <br />11.1
        本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照国际惯例和/或行业惯例。
        <br />11.2
        凡因执行本协议所发生的或与本协议有关的一切争议，应友好协商解决；如果协商不能解决，应提交上海仲裁委员会（SHAC），根据该会的仲裁规则进行仲裁。仲裁裁决是终局性的，对各方都有约束力。
        <br />12. 协议签订、生效、修订与解释 <br />12.1
        您理解并同意在本平台交易所订立的合同采用电子合同方式或线下盖章签署合同，即您使用本平台账户ID在平台上通过点击确认或类似方式签署的电子合同或线下盖章签署的合同即视为您真实意愿及以您名义签署的合同，具有法律效力。
        <br />12.2
        您以前述方式签署电子合同后不得擅自修改合同。如您对电子合同的内容有任何疑问，您可以在管理员账户里查阅合同，若对此有任何争议的，应以本平台记录的合同为准。
        <br />12.3
        本平台保留对本服务条款进行修订、增补和解释的权利。本平台对本服务条款的修订、增补，经在本平台上发布或公开张贴、刊登广告或以本平台认为合适的其它方式向您作出合理通知后生效。
        <br />12.4
        您在本服务条款作出修订、增补生效日当日或其后，继续保留或使用本平台服务的，即视为您已接受有关修订、增补，此等修订、增补即对用户具有约束力。
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AliyunCaptcha from "../components/AliyunCaptcha.vue";
export default {
  components: {
    AliyunCaptcha,
  },
  data() {
    return {
      name: "",
      phone: "",
      imgCode: "", //原图形验证码
      smsCode: "",
      checked: true,
      dialogVisible: false,
      timer: null,
      count: 0,
      plantId: 0,
      captchaVerifyParam: null,
    };
  },
  mounted() {
    this.$store.commit("setSignIsShow", false);
    this.plantId = this.getUrlKey("source");
  },
  beforeDestroy() {
    this.$store.commit("setSignIsShow", true);
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    getUrlKey(name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(location.href) || [, ""])[1].replace(
            /\+/g,
            "%20",
          ),
        ) || null
      );
    },
    //发送验证码
    sendSms() {
      if (!this.checkInput()) return;
      this.$refs.AliyunCaptcha && this.$refs.AliyunCaptcha.handleCaptchaButton(this.phone);
    },
    getBizResult(res) {
      this.captchaVerifyParam = res.captchaVerifyParam;
      if (res.data) {
        this.getCode();
      }
    },
    checkInput() {
      if (!this.phone) {
        this.$commonFun.toast("请输入预约手机号");
        return false;
      }
      if (this.phone.length !== 11) {
        this.$commonFun.toast("请输入正确的手机号码");
        return false;
      }
      return true;
    },
    //验证码倒计时
    getCode() {
      const TIME_COUNT = 60;
      this.count = TIME_COUNT;
      this.timer = setInterval(() => {
        if (this.count > 0) {
          this.count--;
        } else {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },
    //预约表单信息提交
    submits() {
      if (!this.checkInput()) return;
      if (!this.smsCode) {
        this.$commonFun.toast("请输入短信验证码");
        return;
      }
      if (!this.checked) {
        this.$commonFun.toast("请同意并阅读差旅管家TripWise服务使用协议");
        return;
      }
      this.$api
        .experience({
          PlatId: this.plantId || "1039",
          ContactsName: this.phone, //姓名隐藏传手机号
          ContactsPhone: this.phone,
          SMSCode: this.smsCode,
          captchaVerifyParam: this.captchaVerifyParam,
          Version: "5.0.3",
        })
        .then((res) => {
          //申请成功
          this.$alert("提交成功，1个工作日内会有专属顾问联系您。", "体验提醒");
          setTimeout(() => {
            this.$router.push({ path: "/" });
          }, 1500);
        });
    },
  },
};
</script>

<style lang="less">
.appoint {
  width: 100%;
  padding: 80px 0 90px;
  overflow: hidden;
  background: url(https://image.ceekee.com/pc/tripwise/2021014_2.png) center no-repeat;
  background-size: cover;
  .appointBox {
    width: 80%;
    max-width: 1000px;
    margin: 105px auto 0;
    height: 540px;
    .appointLeft {
      width: 43%;
      height: 100%;
      float: left;
      background: #0b7aff;
      border-radius: 18px 0px 0px 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .appointright {
      float: left;
      width: 57%;
      box-sizing: border-box;
      padding: 40px 0 40px 80px;
      height: 100%;
      background: #fff;
      border-radius: 0 18px 18px 0px;
      & > p {
        font-size: 32px;
        font-weight: 500;
        line-height: 56px;
        // margin-bottom: 40px;
        color: rgba(0, 0, 0, 0.85);
      }
      button {
        width: 200px;
        line-height: 25px;
        font-size: 20px;
      }
      .inputLi {
        width: 335px;
        height: 40px;
        background: #ffffff;
        margin-bottom: 20px;
        line-height: 40px;
        p {
          float: right;
          font-size: 14px;
          line-height: 40px;
          color: #0b7afe;
          cursor: pointer;
          width: 80px;
          text-align: center;
        }
        .liBorder {
          float: left;
          border: 1px solid #d8d8d8;
          border-radius: 4px;
          height: 40px;
          width: 100%;
          &.code {
            width: 230px;
          }
          .iconfont {
            font-size: 25px !important;
            color: #bebebe;
            float: left;
            margin: 0 0 0 10px;
            &.iconyonghu,
            &.icontuxingyanzhengma {
              font-size: 20px !important;
            }
          }
          .el-input {
            float: left;
            width: calc(100% - 55px);
            .el-input__inner {
              border: none !important;
            }
          }
        }
        .checkInfo {
          float: left;
          font-size: 14px;
          span {
            color: #0b7afe;
            cursor: pointer;
          }
          &.checkBox {
            width: 30px;
          }
        }
      }
    }
  }
}
</style>
